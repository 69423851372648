/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
/* eslint-disable prefer-destructuring */
import moment from 'moment'
import vSelect from 'vue-select'
import {
  BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-select.scss'
import { toInteger } from 'lodash'
import { mapState } from 'vuex'
import { isNumber } from '@/libs/helpers'
import phoneInput from '@/views/components/input/phoneInput.vue'
import ModalBundlingQuantity from '@/views/components/modal/ModalBundling.vue'
import modalLimitOrder from './Modal/ModalLimitOrder.vue'
import modalErrorStore from './Modal/ModalErrorStore.vue'
import modalCheckPickupAddress from './Modal/ModalAddressPickup.vue'
import modalBundling from './Modal/ModalBundling.vue'

export default {
  components: {
    vSelect, BFormTextarea, modalLimitOrder, modalErrorStore, modalCheckPickupAddress, modalBundling, ModalBundlingQuantity, phoneInput,
  },
  data() {
    return {
      isNumber,
      dateOrder: moment().format('YYYY-MM-DD'),
      address: [],
      addressList: [],
      addressLength: null,
      customerId: null,
      customerName: '',
      customerPhone: '',
      customerAddress: '',
      customerList: [],
      valuePhone: '',
      destination: null,
      destinationSearch: false,
      destinationList: [],
      product: [],
      productList: [],
      productListDB: [],
      productSelected: [],
      productLength: null,
      productStock: null,
      productFields: [
        { key: 'no', label: 'No', thClass: 'align-middle' },
        {
          key: 'product_name',
          label: 'Nama Produk',
          tdClass: 'px-0',
          thClass: 'align-middle',
        },
        {
          key: 'variant',
          label: 'Variasi',
          tdClass: 'px-0',
          thClass: 'align-middle',
        },
        {
          key: 'price',
          label: 'Harga Satuan',
          thClass: 'align-middle',
        },
        {
          key: 'amount',
          label: 'Jumlah',
          tdClass: 'px-0',
          thClass: 'align-middle',
        },
        {
          key: 'subtotal',
          label: 'Subtotal',
          tdClass: 'px-0',
          thClass: 'align-middle',
        },
        { key: 'action', label: 'Hapus Produk', thClass: 'align-middle' },
      ],
      productVariantId: null,
      productVariantParent: null,
      productVariantName: null,
      productVariantOption: null,
      productHistory: false,
      loadingSearch: false,
      loadingCalculate: false,
      cartId: [],
      isCalculate: false,
      isCalculateOnExpedition: false,
      isShipping: false,
      orderNotes: '',
      paymentMethod: null,
      paymentHistory: false,
      listPayment: ['COD', 'BANK TRANSFER'],
      rekening: null,
      listRekening: [],
      totalRekening: 0,
      bankName: null,
      bankAccountName: null,
      bankAccountNo: null,
      shipping: null,
      listShipping: [],
      shippingCost: null,
      serviceFee: null,
      serviceFeePercentage: null,
      weight: null,
      cashback: null,
      cashbackPercentage: null,
      potonganSaldo: false,
      discount: 0,
      biayaLain: false,
      jenisBiayaLain: null,
      sesuaiNominal: 0,
      bebankanCustomer: 0,
      additionalCost: 0,
      subTotal: null,
      netProfit: null,
      grandTotal: null,
      newGrandTotal: null,
      newGrandTotalPaste: null,
      newGrandTotalPasteMode: false,
      isValidate: false,
      formData: null,

      dataErrSubmit: null,
      loadingOptionExpedition: false,

      loadingWrapperOtherCost: false,
      messageErrorLengthCustomerName: false,
      messageErrorPhone: false,
      messageErrorAddressDetail: false,
      oldGrandTotal: null,
      loadingSearchDestination: false,

      itemsCustomLabel: [],
      customLabel: null,

      cartProductId: [],
      idCartDelete: [],
      checkSameCart: {},

      isSubmitOrder: false,
      returnInsight: [],
      showReturnInsight: false,

      isCustomerReputation: false,
      customerReputationOrder: 0,
      customerReputationRetur: 0,
      customerReputation: 0,
      customerReputationClass: 'wrapper__customer__reputation__good',
      customerReputationStyle: 'height: 20px; width: 2px; background-color: #34A770;',
      loadingCustomerReputation: false,

      destinationLabel: '',

      weightProduct: null,

      soldBy: null,
      soldByList: [],
      collapseCalculate: false,
      minimalCustomerName: false,
      requireCustomerName: false,
      requireCustomerPhone: false,

      isKomship: 0,
      isKompack: 0,

      searchProduct: '',

      warehouseID: 0,
      warehouseType: '',
      limit: 5,
      offset: 0,
      isMoreShow: false,

      insurancePrice: 0,
      checkedInsurance: false,
      insuranceConditions: false,
      isFlat: false,
      shippingType: '',
      oriShippingType: '',

      oldNetProfit: 0,
      errorCodMessage: '',

      // bundling
      bundling: {},

      disabledSubmit: true,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.getLimitOrders()
    window.addEventListener('click', e => {
      const inputCustomer = document.getElementById('inputCustomer')
      const clickCustomer = inputCustomer.contains(e.target)
      if (!clickCustomer) {
        this.customerList = []
      }
      const inputDestination = document.getElementById('inputDestination')
      const clickDestination = inputDestination.contains(e.target)
      if (!clickDestination) {
        this.destinationList = []
      }
    })
  },
  async created() {
    this.$forceUpdate()
    if (this.profile.partner_is_customer_reputation === true) {
      this.isCustomerReputation = await true
    } else {
      this.isCustomerReputation = await false
    }
    await this.checkExpedition()
    await this.getAddress()
    await this.addToCart()
    await this.getRekening()
    await this.getCustomLabel()
    await this.getSalesTrackingList()
    if (
      localStorage.getItem('productSelected')
      && localStorage.productHistory
    ) {
      try {
        this.productSelected = JSON.parse(
          localStorage.getItem('productSelected'),
        )
        this.productHistory = localStorage.productHistory
      } catch (e) {
        localStorage.removeItem('productSelected')
        localStorage.removeItem('productHistory')
      }
    }
    if (localStorage.getItem('rekening')) {
      try {
        this.rekening = JSON.parse(localStorage.getItem('rekening'))
      } catch (e) {
        localStorage.removeItem('rekening')
      }
    }
    if (localStorage.paymentMethod && localStorage.paymentHistory) {
      try {
        this.paymentMethod = localStorage.paymentMethod
        this.paymentHistory = localStorage.paymentHistory
      } catch (e) {
        localStorage.removeItem('paymentMethod')
        localStorage.removeItem('paymentHistory')
      }
    }
  },
  methods: {
    async getLimitOrders(value) {
      this.isSubmitOrder = true
      await this.$http_komship.get('/v1/user/limit-order')
        .then(response => {
          const { data } = response.data
          if (!data.is_lock) {
            if (value === 'submit') {
              this.submit(true)
            } else {
              this.isSubmitOrder = false
              this.$bvModal.hide('modal-validation-add-order')
            }
          } else {
            this.isSubmitOrder = false
            this.$bvModal.show('modal-validation-add-order')
          }
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal tambah order, coba beberapa saat lagi' })
          this.isSubmitOrder = false
        })
    },
    formatDate(date) {
      const monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ]
      const day = moment(date).format('DD')
      const month = moment(date).format('M')
      const year = moment(date).format('YYYY')
      return `${day} ${monthName[month - 1]} ${year}`
    },
    formatNumber: value => `${value}`.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    formatDiscount(value) {
      this.discount = `${value}`.replace(/[^\d]+|^0+(?!$)/g, '')
      if (value === '' || value === null) {
        this.discount = 0
      } else {
        this.discount = `${value}`.replace(/[^\d]+|^0+(?!$)/g, '')
      }
    },
    disableScroll(event) {
      event.preventDefault()
    },
    disableArrowKeys(event) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
      }
    },
    formatAdditional(value) {
      if (value === '' || value === null) {
        this.sesuaiNominal = 0
      } else {
        const num = Number(`${value}`.replace(/[^\d]+|^0+(?!$)/g, ''))
        const total = 100000000 - this.subTotal
        this.sesuaiNominal = num > total ? total : num
      }
    },
    getCustomLabel() {
      this.$http_komship
        .get(`/v1/custom-labels/${this.profile.partner_id}`)
        .then(response => {
          const { data } = response.data
          this.itemsCustomLabel = data
          const defaultLabel = this.itemsCustomLabel.find(
            items => items.is_default === 1,
          )
          const isNotDefaultLabel = this.itemsCustomLabel.find(
            items => items.name_label === this.profile.partner_name,
          )
          if (defaultLabel !== undefined) {
            // eslint-disable-next-line no-plusplus
            for (let x = 0; x < this.itemsCustomLabel.length; x++) {
              if (this.itemsCustomLabel[x].is_default === 1) {
                this.itemsCustomLabel.splice(x, 1)
              }
            }
            this.itemsCustomLabel.unshift(defaultLabel)
            this.customLabel = defaultLabel.id
          }
          if (isNotDefaultLabel !== undefined && defaultLabel === undefined) {
            this.customLabel = isNotDefaultLabel.id
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAddress() {
      this.$http_komship.get('/v3/address').then(async response => {
        const { data } = response.data
        this.addressList = data.map(item => ({
          ...item,
          flag: 'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg',
        }))
        this.addressLength = data.length
        if (this.addressLength !== 0) {
          const result = data.find(item => item.is_default === 1)
          if (result) {
            this.address = result
            this.getProduct(this.address)
          } else {
            this.address = data[0]
            this.getProduct(this.address)
          }
        } else {
          this.$swal({
            title:
              '<span class="font-weight-bold h4">Tambahkan alamat Pick Up untuk melanjutan kegiatan tambah order.</span>',
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Tambahkan Alamat Pick Up',
            confirmButtonClass: 'btn btn-primary',
          }).then(res => {
            if (res.isConfirmed) {
              this.$router.push('gudangku')
            }
          })
        }
      })
    },
    async getReturnInsight() {
      if (this.profile.partner_is_return_insight && this.destination !== null) {
        await this.$http_new.get(`komship/api/v1/retur-insight/city/${this.destination.id}`)
          .then(result => {
            const { data } = result.data
            this.returnInsight = data.map(item => {
              if (item.shipping === 'SAP') {
                return { ...item, is_cod: true }
              }
              return item
            })
            this.showReturnInsight = true
          }).catch(err => {
            this.showReturnInsight = false
            console.error(err)
          })
      } else {
        this.showReturnInsight = false
      }
    },
    getColorReturnInsight(percentage) {
      const value = parseFloat(percentage)
      if (value <= 2) {
        return 'bg-verygood'
      } if (value <= 5) {
        return 'bg-good'
      } if (value <= 15) {
        return 'bg-normal'
      } if (value <= 25) {
        return 'bg-bad'
      } if (value > 25) {
        return 'bg-verybad'
      }
      return null
    },
    searchCustomer: _.debounce(async function (event) {
      const text = event.target.value
      this.customerName = text.replace(/[^A-Za-z-0-9_ , - .]/g, '')
      this.customerList = []
      if (this.customerName !== '') {
        this.customerList = await this.$http_komship.get('v1/customer', {
          params: { search: this.customerName },
        }).then(result => {
          const { data } = result.data
          return data
        }).catch(err => {
          console.error(err)
          return []
        })
      }
      if (this.minimalCustomerName || this.requireCustomerName) {
        this.checkValidationCustomerName()
      }
    }, 1000),
    checkValidationCustomerName() {
      if (this.customerName.length < 3) {
        this.minimalCustomerName = true
      } else {
        this.minimalCustomerName = false
      }
      if (this.customerName === '') {
        this.requireCustomerName = true
      } else {
        this.requireCustomerName = false
      }
    },
    async autofillByCustomer(customer) {
      this.customerId = customer.customer_id
      this.valuePhone = `${toInteger(customer.phone)}`
      this.customerName = customer.name
      if (customer.subdistrict_name !== '') {
        this.destination = await this.$http_komship.get('v2/destination', {
          params: { search: customer.subdistrict_name === '-' ? customer.district_name : customer.subdistrict_name },
        }).then(result => {
          const { data } = result.data.data
          const dataByTarrifCode = data.find(items => items.value === customer.tariff_code)
          this.destinationLabel = dataByTarrifCode.label
          this.changeCalculate()
          return dataByTarrifCode
        }).catch(err => {
          console.error(err)
          return null
        })
      } else {
        this.destination = null
      }
      this.getReturnInsight()
      this.customerAddress = customer.address
      this.customerList = []
    },
    onSearchDestination(search, loading) {
      if (search.length) {
        this.loadingSearchDestination = true
        this.searchDestination(loading, search, this)
      }
    },
    searchDestination: _.debounce((loading, search, that) => {
      that.getDestination(search)
    }, 1000),
    getDestination: _.debounce(function () {
      if (this.destinationLabel.length > 2) {
        this.$http_komship
          .get(`/v2/destination?search=${this.destinationLabel}`)
          .then(res => {
            const { data } = res.data.data
            this.destinationList = data
            this.loadingSearchDestination = false
          })
          .catch(err => {
            this.loadingSearchDestination = false
          })
      }
    }, 1000),
    getProductSearch: _.debounce(async function (search, loading) {
      this.isMoreShow = false
      this.searchProduct = search
      if (this.searchProduct === undefined || this.searchProduct === '') {
        this.searchProduct = ''
        await this.getProduct(this.address)
      } else if (this.searchProduct.length > 2) {
        await this.getProduct(this.address)
      }
    }, 2000),
    async getProduct(address) {
      this.limit = 5
      this.offset = 0
      this.productListDB = []
      this.productList = []
      if (this.warehouseId !== address.warehouse_id) {
        if (address.warehouse_type === 'Mitra Kompack' || this.warehouseType !== address.warehouse_type) {
          this.productSelected = []
          this.insuranceConditions = false
          this.checkedInsurance = false
        }
        this.warehouseType = address.warehouse_type
        this.warehouseId = address.warehouse_id
        this.changeCalculate()
        await this.getProductList(address)
      } else {
        await this.getProductList(address)
      }
    },
    async getProductList(address) {
      await this.$http_komship
        .get(`v3/partner-product/${this.profile.partner_id}`, {
          params: {
            warehouse_type: address.warehouse_type,
            warehouse_id: address.warehouse_id,
            search: this.searchProduct,
            limit: this.limit,
            offset: this.offset,
          },
        })
        .then(response => {
          const { data } = response.data
          this.offset += this.limit
          this.productListDB = data
          this.productList.push(...this.productListDB)
          if (this.productList.length < this.offset) {
            this.isMoreShow = false
          } else {
            this.isMoreShow = true
          }
        })
    },
    async addProduct(itemSelected) {
      if (itemSelected) {
        const result = await this.productSelected.find(
          item => item.product_id === itemSelected.product_id,
        )
        if (
          result === undefined
          || result.length === 0
          || result.variantSubmit
        ) {
          let variantSelected
          if (itemSelected.is_bundling === '1') {
            let optionsName = []
            itemSelected.bundle_component.forEach((bundle, idxBundle) => {
              variantSelected = []
              optionsName = []
              if (bundle.is_variant === '1') {
                bundle.variant.forEach((variant, idxVariant) => {
                  const optionVariantShow = []
                  variant.variant_option.forEach((variantOption, idxVariantOption) => {
                    const isVariantOption = bundle.product_variant.filter(itemProductVariant => itemProductVariant.parent === variantOption.option_parent)
                    const includesOptionName = optionsName.includes(variantOption.option_name)
                    if (variantOption.variant_id === variant.variant_id && !includesOptionName) {
                      optionsName.push(variantOption.option_name)
                      let foundOption = false
                      isVariantOption.forEach(itemOption => {
                        if (itemOption.options_id === variantOption.option_id) {
                          foundOption = true
                          Object.assign(variantOption, {
                            is_active: false,
                            stock: itemOption.stock,
                            is_disabled: idxVariant === bundle.variant.length - 1,
                            price: itemOption.price,
                          })
                        }
                      })
                      if (!foundOption) {
                        // Add new object to bundle.product_variant
                        bundle.product_variant.push({
                          options_id: variantOption.option_id,
                          name: variantOption.option_name,
                          price: 0,
                          stock: 0,
                          parent: variantOption.option_parent,
                        })
                      }
                      optionVariantShow.push(variantOption)
                    }
                  })
                  if (optionVariantShow.length > 0) {
                    variantSelected.push({
                      variant_name: variant.variant_name,
                      variant_id: variant.variant_id,
                      variant_option: optionVariantShow,
                    })
                  }
                })
                Object.assign(bundle, { variantSelected })
              } else {
                variantSelected = []
                const dataTableComp = {
                  stock: bundle.stock,
                  quantity: 1,
                  option_id: bundle.id,
                  price: itemSelected.price,
                  is_active: true,
                  variant_name: '-',
                  subtotal: itemSelected.price,
                }
                Object.assign(bundle, { dataTableComp })
              }
              Object.assign(bundle, { variantSelected })
            })
            itemSelected.stock = this.handleStock(itemSelected)
          } else if (String(itemSelected.is_variant) === '1') {
            const variantOption = await itemSelected.variant[0].variant_option.map(
              item => ({
                option_id: item.option_id,
                option_name: item.option_name,
                option_parent: item.option_parent,
                variant_id: item.variant_id,
                is_active: false,
                is_disabled: false,
              }),
            )
            variantSelected = [
              {
                id: itemSelected.variant[0].id,
                variant_id: itemSelected.variant[0].variant_id,
                variant_name: itemSelected.variant[0].variant_name,
                variant_option: variantOption,
              },
            ]
          } else {
            variantSelected = []
          }
          let isVariant = ''
          if (String(itemSelected.is_bundling) === '1') {
            isVariant = itemSelected.bundle_component.some(optionBundle => String(optionBundle.is_variant) === '1') ? '1' : '0'
          } else {
            isVariant = String(itemSelected.is_variant)
          }
          const bundlingNonVariant = itemSelected.bundle_component.some(optionBundle => String(optionBundle.is_variant) === '1') ? '1' : '0'
          this.productSelected.push({
            product_id: itemSelected.product_id,
            product_image: itemSelected.product_image,
            product_name: itemSelected.product_name,
            bundle_component: itemSelected.bundle_component,
            is_bundling: String(itemSelected.is_bundling),
            is_variant: isVariant,
            bundling_non_variant: bundlingNonVariant,
            variant_id: 0,
            variant_name: String(itemSelected.is_bundling) === '1' && isVariant === '0' ? 'Bundling' : '',
            variant: itemSelected.variant,
            variantProduct: itemSelected.product_variant,
            variantSelected,
            variantButton: false,
            variantSubmit: false,
            quantity: 1,
            price: itemSelected.price,
            subtotal: itemSelected.price,
            stock: itemSelected.stock !== 0 ? itemSelected.stock - 1 : 0,
            stockAvailable: itemSelected.stock !== 0 ? itemSelected.stock : -1,
          })
          this.isCalculateOnExpedition = false
          this.paymentMethod = null
          this.shipping = null
          this.isShipping = false
          this.listShipping = []
          this.productHistory = false
          if (String(bundlingNonVariant) === '0' && String(itemSelected.is_variant) === '0') {
            await this.addToCart()
            await this.getShippingList()
          }
        }
        this.product = []
        this.setInsuranceCondition()
      }
    },
    handleStock(itemSelected) {
      let result
      const optionsIdCounts = {}

      itemSelected.bundle_component.forEach(item => {
        const optionsId = item.id
        optionsIdCounts[optionsId] = (optionsIdCounts[optionsId] || 0) + 1
      })

      if (Object.keys(optionsIdCounts).length > 0) {
        const collectStock = []
        Object.keys(optionsIdCounts).forEach(key => {
          const sameOptionsId = itemSelected.bundle_component.find(item => item.id === Number(key))
          const isStock = sameOptionsId.stock / optionsIdCounts[key]
          collectStock.push(isStock)
        })
        result = Math.floor(Math.min(...collectStock))
      } else {
        result = Math.min(...itemSelected.bundle_component.map(item => item.stock))
      }

      return result
    },
    getVariation(data) {
      if (data.item.is_bundling === '1') {
        this.bundling = data.item
        this.$nextTick(() => {
          this.$bvModal.show('modal-bundling')
        })
      } else {
        this.$root.$emit('bv::show::modal', `modalVariation${data.index}`)
      }
    },
    selectVariant(indexProduct, variantType, optionId) {
      if (variantType === 0) {
        const indexVariantActive = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option.findIndex(
          item => item.is_active === true,
        )
        if (
          this.productSelected[indexProduct].variantSelected[0].variant_option[
            indexVariantActive
          ]
        ) {
          this.productSelected[indexProduct].variantSelected[0].variant_option[
            indexVariantActive
          ].is_active = false
          if (this.productSelected[indexProduct].variantSelected[2]) {
            this.productSelected[indexProduct].variantSelected.splice(1, 2)
          } else if (this.productSelected[indexProduct].variantSelected[1]) {
            this.productSelected[indexProduct].variantSelected.splice(1, 1)
          }
        }
        const indexVariant = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option.findIndex(
          item => item.option_id === optionId,
        )
        this.productSelected[indexProduct].variantSelected[0].variant_option[
          indexVariant
        ].is_active = true
        this.productVariantId = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option[indexVariant].option_id
        this.productVariantParent = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option[indexVariant].option_parent
        this.productVariantName = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option[indexVariant].option_name
        this.productVariantOption = this.productSelected[
          indexProduct
        ].variantSelected[0].variant_option[indexVariant].option_name
        if (this.productSelected[indexProduct].variant[1]) {
          const dataVariant = this.productSelected[
            indexProduct
          ].variant[1].variant_option.filter(
            items => items.option_parent === this.productVariantId,
          )
          const variantOption = dataVariant.map(item => ({
            option_id: item.option_id,
            option_name: item.option_name,
            option_parent: item.option_parent,
            variant_id: item.variant_id,
            is_active: false,
            is_disabled: false,
          }))
          const data = {
            id: this.productSelected[indexProduct].variant[1].id,
            variant_id: this.productSelected[indexProduct].variant[1]
              .variant_id,
            variant_name: this.productSelected[indexProduct].variant[1]
              .variant_name,
            variant_option: variantOption,
          }
          if (
            this.productSelected[indexProduct].variantSelected[1] === undefined
          ) {
            this.productSelected[indexProduct].variantSelected.push(data)
          }
        } else {
          this.productSelected[indexProduct].variantButton = true
        }
      } else if (variantType === 1) {
        const indexVariantActive = this.productSelected[
          indexProduct
        ].variantSelected[1].variant_option.findIndex(
          item => item.is_active === true,
        )
        if (
          this.productSelected[indexProduct].variantSelected[1].variant_option[
            indexVariantActive
          ]
        ) {
          this.productSelected[indexProduct].variantSelected[1].variant_option[
            indexVariantActive
          ].is_active = false
          if (this.productSelected[indexProduct].variantSelected[2]) {
            this.productSelected[indexProduct].variantSelected.splice(2, 1)
          }
        }
        const indexVariant = this.productSelected[
          indexProduct
        ].variantSelected[1].variant_option.findIndex(
          item => item.option_id === optionId,
        )
        this.productSelected[indexProduct].variantSelected[1].variant_option[
          indexVariant
        ].is_active = true
        this.productVariantId = this.productSelected[
          indexProduct
        ].variantSelected[1].variant_option[indexVariant].option_id
        this.productVariantParent = this.productSelected[
          indexProduct
        ].variantSelected[1].variant_option[indexVariant].option_parent
        this.productVariantName += `, ${this.productSelected[indexProduct].variantSelected[1].variant_option[indexVariant].option_name}`
        this.productVariantOption = this.productSelected[
          indexProduct
        ].variantSelected[1].variant_option[indexVariant].option_name
        if (this.productSelected[indexProduct].variant[2]) {
          const dataVariant = this.productSelected[
            indexProduct
          ].variant[2].variant_option.filter(
            items => items.option_parent === this.productVariantId,
          )
          const variantOption = dataVariant.map(item => ({
            option_id: item.option_id,
            option_name: item.option_name,
            option_parent: item.option_parent,
            variant_id: item.variant_id,
            is_active: false,
            is_disabled: false,
          }))
          const data = {
            id: this.productSelected[indexProduct].variant[2].id,
            variant_id: this.productSelected[indexProduct].variant[2]
              .variant_id,
            variant_name: this.productSelected[indexProduct].variant[2]
              .variant_name,
            variant_option: variantOption,
          }
          if (this.productSelected[indexProduct].variantSelected[2]) {
            this.productSelected[indexProduct].variantSelected[2] = data
          } else {
            this.productSelected[indexProduct].variantSelected.push(data)
          }
        } else {
          this.productSelected[indexProduct].variantButton = true
        }
      } else if (variantType === 2) {
        const indexVariantActive = this.productSelected[
          indexProduct
        ].variantSelected[2].variant_option.findIndex(
          item => item.is_active === true,
        )
        if (
          this.productSelected[indexProduct].variantSelected[2].variant_option[
            indexVariantActive
          ]
        ) {
          this.productSelected[indexProduct].variantSelected[2].variant_option[
            indexVariantActive
          ].is_active = false
        }
        const indexVariant = this.productSelected[
          indexProduct
        ].variantSelected[2].variant_option.findIndex(
          item => item.option_id === optionId,
        )
        this.productVariantId = this.productSelected[
          indexProduct
        ].variantSelected[2].variant_option[indexVariant].option_id
        this.productVariantParent = this.productSelected[
          indexProduct
        ].variantSelected[2].variant_option[indexVariant].option_parent
        this.productVariantName += `, ${this.productSelected[indexProduct].variantSelected[2].variant_option[indexVariant].option_name}`
        this.productVariantOption = this.productSelected[
          indexProduct
        ].variantSelected[2].variant_option[indexVariant].option_name
        this.productSelected[indexProduct].variantSelected[2].variant_option[
          indexVariant
        ].is_active = true
        this.productSelected[indexProduct].variantButton = true
      }
    },
    async submitVariant(index, productId) {
      const checkVariant = this.productSelected.findIndex(
        item => item.variant_name === this.productVariantName
          && item.product_id === productId,
      )
      if (checkVariant > -1) {
        this.productSelected.splice(index, 1)
      } else {
        if (this.productSelected[index].variantSelected[2]) {
          const indexVariantOne = this.productSelected[
            index
          ].variantSelected[0].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName = this.productSelected[
            index
          ].variantSelected[0].variant_option[indexVariantOne].option_name
          const indexVariantTwo = this.productSelected[
            index
          ].variantSelected[1].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName += `, ${this.productSelected[index].variantSelected[1].variant_option[indexVariantTwo].option_name}`
          const indexVariantThree = this.productSelected[
            index
          ].variantSelected[2].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName += `, ${this.productSelected[index].variantSelected[2].variant_option[indexVariantThree].option_name}`
        } else if (this.productSelected[index].variantSelected[1]) {
          const indexVariantOne = this.productSelected[
            index
          ].variantSelected[0].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName = this.productSelected[
            index
          ].variantSelected[0].variant_option[indexVariantOne].option_name
          const indexVariantTwo = this.productSelected[
            index
          ].variantSelected[1].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName += `, ${this.productSelected[index].variantSelected[1].variant_option[indexVariantTwo].option_name}`
        } else {
          const indexVariantOne = this.productSelected[
            index
          ].variantSelected[0].variant_option.findIndex(
            item => item.is_active === true,
          )
          this.productVariantName = this.productSelected[
            index
          ].variantSelected[0].variant_option[indexVariantOne].option_name
        }
        const data = this.productSelected[index].variantProduct.filter(
          item => item.parent === this.productVariantParent,
        )
        const dataVariant = data.find(
          item => item.name === this.productVariantOption,
        )
        this.productSelected[index].variant_id = this.productVariantId
        this.productSelected[index].variant_name = this.productVariantName
        this.productSelected[index].stock = dataVariant.stock - 1
        this.productSelected[index].stockAvailable = dataVariant.stock - 1
        this.productSelected[index].price = dataVariant.price
        this.productSelected[index].subtotal = dataVariant.price
        this.productSelected[index].variantSubmit = true
        this.productHistory = false
        await this.addToCart()
        await this.getShippingList()
      }
      this.$root.$emit('bv::hide::modal', `modalVariation${index}`)
      this.setInsuranceCondition()
    },
    setQuantity(status, index) {
      this.changeCalculate()
      this.productHistory = false
      if (status === 'plus') {
        this.productSelected[index].quantity += 1
        this.productSelected[index].stock -= 1
        this.productSelected[index].subtotal = this.productSelected[index].price
          * this.productSelected[index].quantity
        this.productHistory = false
        this.applyChangeQuantity()
      } else if (status === 'minus') {
        this.productSelected[index].quantity -= 1
        this.productSelected[index].stock += 1
        this.productSelected[index].subtotal = this.productSelected[index].price
          * this.productSelected[index].quantity
        this.productHistory = false
        this.applyChangeQuantity()
      }
      this.setInsuranceCondition()
    },
    applyChangeQuantity: _.debounce(async function () {
      await this.addToCart()
      await this.getShippingList()
    }, 1000),
    async removeProduct(data, index) {
      this.destinationList = []
      this.changeCalculate()
      this.idCartDelete = this.cartProductId
      const findCartProduct = this.idCartDelete.find(item => item.product_id === data.item.product_id && item.variant_id === data.item.variant_id)
      const findIndexCartProduct = this.idCartDelete.findIndex(item => item.product_id === data.item.product_id && item.variant_id === data.item.variant_id)
      this.productSelected.splice(index, 1)
      this.productHistory = false
      if (this.productSelected.length === 0) {
        this.paymentMethod = null
        this.shipping = null
        this.listShipping = []
        if (findCartProduct !== undefined) {
          await this.$http_komship.delete('/v1/cart/delete', {
            params: {
              cart_id: [findCartProduct.cart_id],
            },
          })
            .then(async () => {
              await this.addToCart()
              this.shipping = null
              this.listShipping = []
              this.cartId = []
              this.cartProductId = []
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure top',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              })
            })
        }
      } else {
        let cartDelete = null
        cartDelete = this.idCartDelete.find(item => item.product_id === data.item.product_id && item.variant_id === data.item.variant_id)
        if (cartDelete !== undefined) {
          await this.$http_komship.delete('/v1/cart/delete', {
            params: {
              cart_id: [cartDelete.cart_id],
            },
          })
            .then(async () => {
              await this.cartProductId.splice(findIndexCartProduct, 1)
              await this.cartId.splice(findIndexCartProduct, 1)
              await this.addToCart()
              await this.getShippingList()
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure bot',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              })
            })
        }
      }
      this.setInsuranceCondition()
    },
    saveProductHistory() {
      const parsed = JSON.stringify(this.productSelected)
      localStorage.setItem('productSelected', parsed)
      localStorage.productHistory = true
      this.productHistory = true
    },
    removeProductHistory() {
      localStorage.removeItem('productSelected')
      localStorage.removeItem('productHistory')
      this.productHistory = false
    },
    setInsuranceCondition() {
      let countGrandTotal = 0
      this.productSelected.forEach(item => {
        countGrandTotal += item.subtotal
      })
      if (countGrandTotal > 299999) {
        this.insuranceConditions = true
      } else {
        this.insuranceConditions = false
        this.checkedInsurance = false
      }
    },
    changeCalculate() {
      this.isCalculateOnExpedition = false
      this.paymentMethod = null
      this.shipping = null
      this.isShipping = false
      this.listShipping = []
      this.biayaLain = false
      this.potonganSaldo = false
      this.errorCodMessage = ''
    },
    savePaymentHistory() {
      const parsed = JSON.stringify(this.rekening)
      localStorage.setItem('rekening', parsed)
      localStorage.paymentMethod = this.paymentMethod
      localStorage.paymentHistory = true
      this.paymentHistory = true
    },
    removePaymentHistory() {
      localStorage.removeItem('rekening')
      localStorage.removeItem('paymentMethod')
      localStorage.removeItem('paymentHistory')
      this.paymentHistory = false
    },
    getCartId(cart, productId) {
      let result = 0
      if (cart[0] !== undefined) {
        const findCart = cart.find(item => item.variant_id === productId.variant_id && item.product_id === productId.product_id)
        if (findCart !== undefined) {
          if (findCart.variant_id === productId.variant_id) {
            result = findCart.cart_id
          }
        }
      }
      return result
    },
    async addToCart() {
      if (this.productSelected.length > 0) {
        this.loadingCalculate = true
        const cart = this.productSelected.map(items => ({
          id: this.getCartId(this.cartProductId, items),
          product_id: items.product_id,
          product_name: items.product_name,
          variant_id: items.is_bundling === '1' ? 0 : items.variant_id,
          variant_name: items.is_bundling === '1' ? '-' : items.variant_name,
          product_price: items.price,
          qty: items.quantity,
          subtotal: items.subtotal,
          is_bundling: items.is_bundling === '1' ? 1 : 0,
          bundle_component: items.is_bundling !== '1' ? [] : items.bundle_component.map(itemBundle => ({
            product_id: itemBundle.id,
            product_name: itemBundle.product_name,
            variant_id: itemBundle.is_variant === '1' ? itemBundle.dataTableComp.option_id : 0,
            variant_name: itemBundle.dataTableComp.variant_name,
          })),
        }))
        await this.$http_komship
          .post('/v3/cart/bulk-store-web', cart)
          .then(async res => {
            this.cartId = []
            this.cartProductId = res.data.data
            await this.cartProductId.forEach((item, index) => {
              if (item.bundle_component && item.bundle_component.length > 0) {
                const variantIdSum = item.bundle_component.reduce((sum, component) => sum + component.variant_id, 0)
                this.cartProductId[index].variant_id = variantIdSum
              }
              this.cartId.push(item.cart_id)
            })
            this.loadingCalculate = false
            await this.calculate(true)
          })
      } else {
        this.isCalculate = false
        this.isCalculateOnExpedition = false
        this.loadingCalculate = false
      }
    },
    async getRekening() {
      await this.$http_komship.get('v1/bank-account').then(res => {
        const { data } = res.data
        this.listRekening = data
        this.totalRekening = data.length
      })
    },
    validateRekening() {
      this.errorCodMessage = ''
      if (this.paymentMethod === 'BANK TRANSFER' && this.totalRekening === 0 && this.profile.partner_is_mutation_bank === true) {
        this.$swal({
          title:
            '<span class="font-weight-bold h4">Kamu belum menambahkan rekening, silahkan tambahkan rekening terlebih dahulu.</span>',
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Tambah Rekening',
          confirmButtonClass: 'btn btn-primary',
        }).then(result => {
          if (result.isConfirmed) {
            this.$router.push('/setting-kompship/rekening-bank')
          }
        })
      }
    },
    async checkExpedition() {
      await this.$http_komship
        .get('/v1/partner/shipment/not-active')
        .then(res => {
          const { data } = res.data
          const result = data.filter(items => items.is_active === 1)
          if (result.length < 1) {
            this.$swal({
              title:
                '<span class="font-weight-bold h4">Mohon Maaf, Ekspedisi Belum Diaktifkan.</span>',
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
              showCancelButton: true,
              confirmButtonText: 'Aktifkan Ekspedisi',
              confirmButtonClass: 'btn btn-primary',
              cancelButtonText: 'Oke',
              cancelButtonClass:
                'btn btn-outline-primary bg-white text-primary',
            }).then(then => {
              if (then.isConfirmed) {
                this.$router.push('/setting-kompship/ekspedisi')
              }
            })
          }
        })
    },
    getShippingList() {
      this.shipping = null
      this.listShipping = []
      this.isShipping = false
      this.isCalculate = false
      this.isCalculateOnExpedition = false
      this.loadingOptionExpedition = true
      if (this.destination && this.paymentMethod && this.profile && this.address) {
        this.$http_komship.get('v5/calculate', {
          params: {
            cart: this.cartId.toString(),
            receiver_destination: this.destination.id,
            payment_method: this.paymentMethod,
            partner_id: this.profile.partner_id,
            partner_address_id: this.address.warehouse_id,
            warehouse_type: this.address.warehouse_type,
            is_insurance: this.checkedInsurance ? 1 : 0,
          },
        }).then(async res => {
          const { data } = res.data
          this.returnInsight = this.returnInsight.map(item => {
            if (item.shipping === 'SAP') {
              return { ...item, is_cod: data.data_shipping.coverage_cod_sap }
            }
            return item
          })
          const resultReguler = await data.data_regular.map(items => ({
            label: `${items.shipment_name} - ${items.shipment_alias} - Rp${this.formatNumber(items.shipping_cost)}`,
            value: items.value,
            image_path: items.image_path,
            shipment_name: items.shipment_name,
            label_shipping_type: items.shipment_alias,
            shipping_type: items.shipping_type,
            shipping_cost: items.shipping_cost,
            is_flat: items.is_flat,
            expeditions_type: 'Reguler',
          }))
          if (data.data_regular.length !== 0) resultReguler.unshift({ label: 'Reguler' })
          const resultTruck = await data.data_truck.map(items => ({
            label: `${items.shipment_name} - ${items.shipment_alias} - Rp${this.formatNumber(items.shipping_cost)}`,
            value: items.value,
            image_path: items.image_path,
            shipment_name: items.shipment_name,
            label_shipping_type: items.shipment_alias,
            shipping_type: items.shipping_type,
            shipping_cost: items.shipping_cost,
            expeditions_type: 'Truck',
          }))
          if (data.data_truck.length !== 0) resultTruck.unshift({ label: 'Cargo' })
          this.listShipping = resultReguler.concat(resultTruck)
          this.isShipping = true
          this.loadingOptionExpedition = false
          if (this.shipping !== null) {
            const findShipping = this.listShipping.find(items => items.shipment_name === this.shipping.shipment_name)
            if (findShipping !== undefined) {
              this.shipping = findShipping
            } else {
              this.shipping = null
            }
          }
          this.collapseCalculate = true
        }).catch(err => {
          if (err?.response?.data?.message === 'Please Complete Your Address.') {
            this.$bvModal.show('modal-check-address-pickup')
          } else if (err.response.data.message === 'destination address is not available for COD payment method') {
            this.returnInsight = this.returnInsight.map(item => {
              if (item.shipping === 'SAP') {
                return { ...item, is_cod: false }
              }
              return item
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            })
          }
          this.loadingOptionExpedition = false
        })
      } else {
        this.shipping = null
        this.listShipping = []
        this.isShipping = false
        this.isCalculate = false
        this.isCalculateOnExpedition = false
        this.loadingOptionExpedition = false
      }
    },
    checkNewTotal: _.debounce(async function () {
      if (this.checkedInsurance) {
        if (this.newGrandTotal < 250000 + this.shippingCost) {
          this.newGrandTotal = 250000 + this.shippingCost
        } else if (this.newGrandTotal > 100000000 + this.shippingCost) {
          this.newGrandTotal = 100000000 + this.shippingCost
        } else {
          this.newGrandTotalPasteMode = false
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.newGrandTotal <= this.shippingCost) {
          this.newGrandTotal = await this.shippingCost
          this.newGrandTotalPasteMode = false
        } else {
          this.newGrandTotalPasteMode = false
        }
      }
      await this.calculate(false)
    }, 2000),
    checkTotal(e) {
      if (e.keyCode !== 65 && e.code !== 'ControlLeft' && e.code !== 'ControlRight') {
        if (this.newGrandTotal.toString().length >= 4) {
          if (this.newGrandTotalPasteMode) this.newGrandTotal = this.newGrandTotalPaste
          this.loadingCalculate = true
          this.checkNewTotal()
        }
      }
    },
    handlePasteCheckTotal(e) {
      this.newGrandTotalPasteMode = true
      this.newGrandTotalPaste = e.clipboardData.getData('text').replace(/[^\d]/g, '')
    },
    formatterNewGrandTotal(e) {
      let result = e.replace(/[^\d]/g, '').substring(0, 8)
      if (this.checkedInsurance) {
        result = e.replace(/[^\d]/g, '').substring(0, 9)
      }
      return result
    },
    checkDiscount(discount) {
      if (!this.checkedInsurance && discount > this.subTotal) {
        this.discount = this.subTotal
        this.calculate(true)
      }
      if (this.checkedInsurance) {
        if (discount > this.subTotal - 250000) {
          this.discount = this.subTotal - 250000
          this.calculate(true)
        }
      }
    },
    calculate: _.debounce(async function (getAdditional) {
      if (this.shipping && this.cartId.length > 0) {
        this.loadingCalculate = true
        let grandTotalNew
        if (this.biayaLain && this.jenisBiayaLain === '1') {
          this.additionalCost = this.sesuaiNominal
        } else if (this.biayaLain && this.jenisBiayaLain === '0') {
          let conditionalServiceFee = 0
          const serviceFee = (((this.subTotal - this.discount) + this.shippingCost) * this.serviceFeePercentage) / 100
          this.bebankanCustomer = ((serviceFee + (this.subTotal - this.discount) + this.shippingCost) * this.serviceFeePercentage) / 100
          if (this.shipping.shipment_name === 'JNE' || this.shipping.shipment_name === 'SICEPAT') {
            conditionalServiceFee = 1
          }
          this.additionalCost = Math.round((((this.bebankanCustomer + (this.subTotal - this.discount) + this.shippingCost) * this.serviceFeePercentage) / 100) + conditionalServiceFee)
          this.bebankanCustomer = this.additionalCost
          this.sesuaiNominal = this.additionalCost
        } else {
          this.additionalCost = 0
        }
        if (!this.potonganSaldo) {
          this.discount = 0
        }
        if (this.profile.partner_is_allowed_edit) {
          if (getAdditional) {
            grandTotalNew = null
          } else {
            grandTotalNew = this.newGrandTotal
          }
        } else {
          grandTotalNew = null
        }
        await this.$http_komship.get('v5/calculate', {
          params: {
            cart: this.cartId.toString(),
            receiver_destination: this.destination.id,
            payment_method: this.paymentMethod,
            partner_id: this.profile.partner_id,
            partner_address_id: this.address.warehouse_id,
            discount: this.discount,
            additional_cost: this.additionalCost,
            grandtotal: grandTotalNew,
            warehouse_type: this.address.warehouse_type,
            is_insurance: this.checkedInsurance ? 1 : 0,
          },
        }).then(async res => {
          const { data } = res.data
          const valueRes = ['Reguler', 'IDLITE', 'SAPFLAT']
          const valueDef = ['Reguler', 'IDLITE']
          const result = valueRes.includes(this.shipping.label_shipping_type) ? data.data_regular.find(items => items.value === this.shipping.value) : data.data_truck.find(items => items.value === this.shipping.value)
          const resultDefault = valueDef.includes(this.shipping.label_shipping_type) ? data.data_regular.find(items => items.shipment_name === this.shipping.shipment_name) : data.data_truck.find(items => items.shipment_name === this.shipping.shipment_name)
          if (result !== undefined) {
            if (getAdditional) {
              this.sesuaiNominal = Math.round(result.service_fee)
              this.bebankanCustomer = Math.round(result.service_fee)
              this.newGrandTotal = result.grandtotal
              this.oldGrandTotal = result.grandtotal
              this.weightProduct = result.weight
              this.weight = result.weight.toFixed(1)
              this.insurancePrice = result.insurance_fee
              this.oldNetProfit = result.old_net_profit
              if (this.paymentMethod === 'COD') {
                this.jenisBiayaLain = '0'
              } else {
                this.jenisBiayaLain = '1'
              }
              this.isFlat = result.is_flat
              this.shippingType = result.shipping_type
              this.oriShippingType = result.ori_shipping_type
            }
            if (this.newGrandTotal === null) {
              this.newGrandTotal = result.grandtotal
            }
            if (!this.profile.partner_is_allowed_edit || Number(this.newGrandTotal) === Number(result.grandtotal)) {
              this.subTotal = result.subtotal
              this.shippingCost = result.shipping_cost
              this.netProfit = result.net_profit
              this.bebankanCustomer = Math.round(result.service_fee)
              this.serviceFee = Math.round(result.service_fee)
              this.serviceFeePercentage = result.service_fee_percentage
              this.weight = result.weight.toFixed(1)
              this.grandTotal = result.grandtotal
              this.cashback = result.cashback
              this.cashbackPercentage = result.cashback_percentage
              this.additionalCost = result.additional_cost
              this.isCalculate = true
              this.loadingCalculate = false
              this.disabledSubmit = false
              this.insurancePrice = result.insurance_fee
              this.isFlat = result.is_flat
              this.shippingType = result.shipping_type
              this.oriShippingType = result.ori_shipping_type
              this.oldNetProfit = result.old_net_profit
            }
            this.loadingCalculate = false
            this.disabledSubmit = false
          } else {
            if (getAdditional) {
              this.sesuaiNominal = Math.round(resultDefault.service_fee)
              this.bebankanCustomer = Math.round(resultDefault.service_fee)
              this.newGrandTotal = resultDefault.grandtotal
              this.oldGrandTotal = resultDefault.grandtotal
              this.weight = resultDefault.weight.toFixed(1)
              this.insurancePrice = resultDefault.insurance_fee
              this.oldNetProfit = result.old_net_profit
              if (this.paymentMethod === 'COD') {
                this.jenisBiayaLain = '0'
              } else {
                this.jenisBiayaLain = '1'
              }
              this.isFlat = result.is_flat
              this.shippingType = result.shipping_type
              this.oriShippingType = result.ori_shipping_type
            }
            if (this.newGrandTotal === null) {
              this.newGrandTotal = resultDefault.grandtotal
            }
            if (!this.profile.partner_is_allowed_edit || Number(this.newGrandTotal) === Number(resultDefault.grandtotal)) {
              this.subTotal = resultDefault.subtotal
              this.shippingCost = resultDefault.shipping_cost
              this.netProfit = resultDefault.net_profit
              this.serviceFee = Math.round(resultDefault.service_fee)
              this.bebankanCustomer = Math.round(resultDefault.service_fee)
              this.serviceFeePercentage = resultDefault.service_fee_percentage
              this.weight = resultDefault.weight.toFixed(1)
              this.grandTotal = resultDefault.grandtotal
              this.cashback = resultDefault.cashback
              this.cashbackPercentage = resultDefault.cashback_percentage
              this.additionalCost = resultDefault.additional_cost
              this.weightProduct = resultDefault.weight
              this.isCalculate = true
              this.loadingCalculate = false
              this.disabledSubmit = false
              this.insurancePrice = resultDefault.insurance_fee
              this.isFlat = result.is_flat
              this.shippingType = result.shipping_type
              this.oriShippingType = result.ori_shipping_type
              this.oldNetProfit = result.old_net_profit
            }
            this.loadingCalculate = false
            this.disabledSubmit = false
          }
          if (this.newGrandTotal === this.shippingCost) {
            this.netProfit = result.net_profit
          }
        }).catch(async err => {
          this.loadingWrapperOtherCost = false
          this.loadingCalculate = false
          this.disabledSubmit = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
      } else {
        this.isCalculate = false
        this.loadingWrapperOtherCost = false
      }
    }, 1000),
    calculateOnExpedition: _.debounce(function (getAdditional, dataOption) {
      this.collapseCalculate = false
      this.shipping = dataOption
      this.loadingWrapperOtherCost = true
      if (this.shipping && this.cartId.length > 0) {
        this.loadingCalculate = true
        let grandTotalNew
        this.biayaLain = false
        this.additionalCost = 0
        if (!this.potonganSaldo) {
          this.discount = 0
        }
        if (this.profile.partner_is_allowed_edit) {
          if (getAdditional) {
            grandTotalNew = null
          } else {
            grandTotalNew = this.newGrandTotal
          }
        } else {
          grandTotalNew = null
        }
        this.checkDiscount(this.discount)
        this.$http_komship.get('v5/calculate', {
          params: {
            cart: this.cartId.toString(),
            receiver_destination: this.destination.id,
            payment_method: this.paymentMethod,
            partner_id: this.profile.partner_id,
            partner_address_id: this.address.warehouse_id,
            discount: this.discount,
            additional_cost: this.additionalCost,
            grandtotal: grandTotalNew,
            warehouse_type: this.address.warehouse_type,
            is_insurance: this.checkedInsurance ? 1 : 0,
          },
        }).then(async res => {
          const { data } = res.data
          const result = this.shipping.expeditions_type === 'Reguler' ? data.data_regular.find(items => items.value === this.shipping.value) : data.data_truck.find(items => items.value === this.shipping.value)
          if (getAdditional) {
            this.sesuaiNominal = Math.round(result.service_fee)
            this.bebankanCustomer = Math.round(result.service_fee)
            this.newGrandTotal = result.grandtotal
            this.oldGrandTotal = result.grandtotal
            this.weight = result.weight.toFixed(1)
            this.insurancePrice = result.insurance_fee
            this.oldNetProfit = result.old_net_profit
            if (this.paymentMethod === 'COD') {
              this.jenisBiayaLain = '0'
            } else {
              this.jenisBiayaLain = '1'
            }
            this.isFlat = result.is_flat
            this.shippingType = result.shipping_type
            this.oriShippingType = result.ori_shipping_type
          }
          if (this.newGrandTotal === null) {
            this.newGrandTotal = result.grandtotal
          }
          if (!this.profile.partner_is_allowed_edit || Number(this.newGrandTotal) === Number(result.grandtotal)) {
            this.subTotal = result.subtotal
            this.shippingCost = result.shipping_cost
            this.bebankanCustomer = Math.round(result.service_fee)
            this.netProfit = result.net_profit
            this.serviceFee = Math.round(result.service_fee)
            this.serviceFeePercentage = result.service_fee_percentage
            this.weight = result.weight.toFixed(1)
            this.grandTotal = result.grandtotal
            this.cashback = result.cashback
            this.cashbackPercentage = result.cashback_percentage
            this.additionalCost = result.additional_cost
            this.isCalculateOnExpedition = true
            this.disabledSubmit = false
            this.loadingCalculate = false
            this.insurancePrice = result.insurance_fee
            this.oldNetProfit = result.old_net_profit
            this.isFlat = result.is_flat
            this.shippingType = result.shipping_type
            this.oriShippingType = result.ori_shipping_type
          }
          this.weight = result.weight.toFixed(1)
          this.loadingWrapperOtherCost = false
          this.loadingCalculate = false
          this.disabledSubmit = false
        }).catch(async err => {
          this.loadingWrapperOtherCost = false
          this.loadingCalculate = false
          this.disabledSubmit = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
      } else {
        this.isCalculateOnExpedition = false
        this.loadingWrapperOtherCost = false
      }
    }, 1000),
    checkValidation() {
      if (
        this.paymentMethod === 'BANK TRANSFER'
        && this.rekening
        && this.profile.partner_is_mutation_bank
      ) {
        this.bankName = this.rekening.bank_name
        this.bankAccountName = this.rekening.account_name
        this.bankAccountNo = this.rekening.account_no
      } else {
        this.bankName = 0
        this.bankAccountName = 0
        this.bankAccountNo = 0
      }
      if (
        this.paymentMethod === 'BANK TRANSFER'
        && this.rekening
        && this.customerName
        && this.customerPhone
        && this.customerAddress
      ) {
        this.isValidate = true
      } else if (
        this.paymentMethod === 'BANK TRANSFER'
        && !this.profile.partner_is_mutation_bank
        && this.customerName
        && this.customerPhone
        && this.customerAddress
      ) {
        this.isValidate = true
      } else if (
        this.paymentMethod === 'COD'
        && this.customerName
        && this.customerPhone
        && this.customerAddress
      ) {
        this.isValidate = true
      } else {
        this.isValidate = false
      }
      if (this.profile.partner_is_tracking_sales) {
        if (this.soldBy === null) {
          this.isValidate = false
        } else {
          this.isValidate = true
        }
      }
      if (this.address.warehouse_type === 'Private Warehouse') {
        this.isKomship = 1
        this.isKompack = 0
      } else {
        this.isKomship = 0
        this.isKompack = 1
      }
      this.formData = {
        date: this.dateOrder,
        tariff_code: this.destination.value,
        subdistrict_name: this.destination.subdistrict_name,
        zip_code: this.destination.zip_code,
        district_name: this.destination.district_name,
        city_name: this.destination.city_name,
        is_komship: this.isKomship,
        is_kompack: this.isKompack,
        tracking_sales_id: this.soldBy,
        customer_id: this.customerId,
        customer_name: this.customerName,
        customer_phone: this.customerPhone,
        customer_destination_id: this.destination.id,
        detail_address: this.customerAddress,
        shipping: this.shipping.shipment_name,
        shipping_type: this.shipping.shipping_type,
        ori_shipping_type: this.oriShippingType,
        is_flat: this.isFlat ? 1 : 0,
        payment_method: this.paymentMethod,
        bank: this.bankName,
        partner_address_id: this.address.warehouse_id,
        bank_account_name: this.bankAccountName,
        bank_account_no: this.bankAccountNo,
        subtotal: this.subTotal,
        grandtotal: this.grandTotal,
        old_grandtotal: this.oldGrandTotal,
        shipping_cost: this.shippingCost,
        service_fee: this.serviceFee,
        discount: this.discount,
        additional_cost: this.additionalCost,
        shipping_cashback: this.cashback,
        net_profit: this.netProfit,
        cart: this.cartId,
        custom_label_id: this.customLabel,
        order_notes: this.orderNotes,
        is_whatsapp: this.isWhatsapp === 'valid' ? 1 : 0,
        warehouse_id: this.address.warehouse_id,
        is_insurance: this.checkedInsurance ? 1 : 0,
        insurance_fee: this.checkedInsurance ? this.insurancePrice : 0,
        old_net_profit: this.oldNetProfit,
      }
      if (this.profile.partner_is_tracking_sales) Object.assign(this.formData, { tracking_sales_id: this.soldBy.id })
    },
    handleCustomLabel(items) {
      this.customLabel = items
    },
    // getLimitOrder() {
    //   this.$http_komship.get('/v1/user/limit-order')
    //     .then(response => {
    //       const { data } = response.data
    //       if (!data.is_lock) {
    //         this.submit(true)
    //       } else {
    //         this.$bvModal.show('modal-validation-add-order')
    //       }
    //     })
    // },
    async submit(order) {
      this.isSubmitOrder = true
      this.checkValidation()
      if (this.isValidate) {
        await this.$http_komship
          .post(`v4/order/${this.profile.partner_id}/store`, this.formData)
          .then(() => {
            this.isSubmitOrder = false
            this.$swal({
              title:
                '<span class="font-weight-bold h4">Berhasil Tambah Order</span>',
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/success.svg',
              confirmButtonText: 'Oke',
              confirmButtonClass: 'btn btn-primary',
            }).then(() => {
              if (order) {
                window.location.reload()
              } else {
                this.$router.push('/data-order')
              }
              this.isSubmitOrder = false
            })
          })
          .catch(err => {
            this.dataErrSubmit = err.response.data
            if (
              this.dataErrSubmit.message === 'Please Topup to continue your store Order.'
              || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a postage payment'
              || this.dataErrSubmit.message === 'Sorry, there is not enough stock to continue the order'
              || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a transaction'
              || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a transaction (not meet minimum point)'
            ) {
              let nameButton = ''
              let titleAlert = ''
              let description = ''
              if (
                this.dataErrSubmit.message
                === 'Please Topup to continue your store Order.'
              ) {
                nameButton = 'Cek Saldo'
                titleAlert = 'Mohon Maaf, saldo anda tidak mencukupi untuk membuat order. Silahkan cek kembali saldo anda.'
              } else if (
                this.dataErrSubmit.message
                === 'Sorry, your balance is not enough to make a postage payment'
              ) {
                nameButton = 'Top up Saldo'
                titleAlert = 'Saldo Belum Mencukupi'
                description = 'Kamu harus mengisi saldo dulu ya, sebelum membuat order dengan metode Transfer Bank'
              } else if (
                this.dataErrSubmit.message
                === 'Sorry, your balance is not enough to make a transaction'
                || this.dataErrSubmit.message
                === 'Sorry, your balance is not enough to make a transaction (not meet minimum point)'
              ) {
                nameButton = 'Top up Saldo'
                titleAlert = 'Saldo Belum Mencukupi'
                description = 'Kamu harus mengisi saldo dulu ya, sebelum membuat order'
              } else if (
                this.dataErrSubmit.message
                === 'Sorry, there is not enough stock to continue the order'
              ) {
                nameButton = 'Cek Produk'
                titleAlert = 'Mohon maaf, stok produk kamu tidak mencukupi untuk membuat orderan ini. Silahkan tambahkan stok produk terlebih dahulu'
              }
              this.$swal({
                title: `<span class="text-bold text-lg">${titleAlert}</span>`,
                text: nameButton === 'Top up Saldo' ? description : '',
                imageUrl: nameButton === 'Top up Saldo' ? 'https://storage.googleapis.com/komerce/assets/icons/warning.svg' : 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
                showCancelButton: true,
                confirmButtonText: 'Kembali',
                confirmButtonClass: 'btn btn-outline-primary bg-white text-primary',
                cancelButtonText: nameButton,
                cancelButtonClass:
                  'btn btn-primary',
              }).then(result => {
                if (result.isDismissed) {
                  if (
                    this.dataErrSubmit.message === 'Please Topup to continue your store Order.'
                    || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a postage payment'
                    || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a transaction'
                    || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a transaction (not meet minimum point)'
                  ) {
                    this.$router.push({ name: 'dashboard-komship', params: { topup: true } })
                  }
                  if (
                    this.dataErrSubmit.message
                    === 'Sorry, there is not enough stock to continue the order'
                  ) {
                    this.$router.push('/produk')
                  }
                }
              })
            } else {
              this.$bvModal.show('modal-error-store-order')
            }
            this.isSubmitOrder = false
          })
      } else {
        this.$swal({
          title:
            '<span class="font-weight-bold h4">Tidak Boleh Ada Field Yang Kosong!</span>',
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
        this.isSubmitOrder = false
      }
    },
    formatAddressDetail(e) {
      return String(e).substring(0, 120)
    },
    formatOrderNotes(e) {
      return String(e).substring(0, 50)
    },
    refreshPage() {
      window.location.reload()
    },
    getCustomerReputation() {
      this.loadingCustomerReputation = true
      const formData = new FormData()
      formData.append('customer_phone', this.customerPhone)
      this.$http_komship.get('/v1/feature/customerReputation', {
        params: {
          customer_phone: this.customerPhone,
        },
      })
        .then(response => {
          const { data } = response.data
          if (data.customer_rep !== undefined) {
            this.customerReputation = parseFloat(data.customer_rep).toFixed(2)
            this.customerReputationOrder = data.total_order
            this.customerReputationRetur = data.total_retur
            if (this.customerReputation < 10) {
              this.customerReputationClass = 'wrapper__customer__reputation__good'
              this.customerReputationStyle = 'height: 20px; width: 2px; background-color: #34A770;'
            } else if (this.customerReputation > 10 && this.customerReputation < 50) {
              this.customerReputationClass = 'wrapper__customer__reputation__middle'
              this.customerReputationStyle = 'height: 20px; width: 2px; background-color: #FBA63C'
            } else if (this.customerReputation > 50) {
              this.customerReputationClass = 'wrapper__customer__reputation__bad'
              this.customerReputationStyle = 'height: 20px; width: 2px; background-color: #E31A1A'
            }
          } else {
            this.customerReputation = 0
            this.customerReputationOrder = 0
            this.customerReputationRetur = 0
          }
          this.loadingCustomerReputation = false
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          })
          this.loadingCustomerReputation = false
        })
    },
    applyDestination(items) {
      this.destinationList = []
      this.changeCalculate()
      this.destination = items
      this.destinationLabel = items.label
      this.getReturnInsight()
      this.destinationList = []
    },
    getSalesTrackingList() {
      this.$http_komship.get('/v1/tracking-sales/list')
        .then(response => {
          this.soldByList = response.data.data
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
    },
    formatText(event) {
      const text = event.target.value
      let string = ''
      for (let x = 0; x < text.length; x++) {
        if (text.charAt(x) === '='
        || text.charAt(x) === ';'
        || text.charAt(x) === '"'
        || text.charAt(x) === '&'
        || text.charAt(x) === '*'
        || text.charAt(x) === '#'
        || text.charAt(x) === '&'
        || text.charAt(x) === '%'
        || text.charAt(x) === '['
        || text.charAt(x) === ']'
        || text.charAt(x) === '>'
        || text.charAt(x) === '<'
        || text.charAt(x) === '|'
        || text.charAt(x) === '+'
        || text.charAt(x) === '?'
        || text.charAt(x) === '!'
        || text.charAt(x) === ':') {
          string += text.charAt(x)
        } else {
          string += text.charAt(x).replace(/[^A-Za-z-0-9_ , - . / ( )]/g, '')
        }
      }
      this.customerAddress = string
      if (text.match(/[^A-Za-z-0-9_ , - . / ( )]/g)) {
        this.messageErrorAddressDetail = true
      } else {
        this.messageErrorAddressDetail = false
      }
    },
    validateInputAddressDetail(e) {
      if (
        e.keyCode === 61
        || e.keyCode === 58
        || e.keyCode === 59
      ) {
        this.messageErrorAddressDetail = true
      } else {
        this.messageErrorAddressDetail = false
      }
    },
    handleInputTotal(e) {
      if (e.keyCode === 44 || e.keyCode === 45 || e.keyCode === 46 || e.keyCode === 43 || e.keyCode === 101) {
        e.preventDefault()
      }
    },
    maxCod() {
      let result = false
      if (this.paymentMethod === 'COD') {
        if (this.grandTotal < 10000) {
          result = true
          this.errorCodMessage = 'Nominal COD minimal Rp10.000,-'
        } else if (this.grandTotal > 5000000) {
          result = true
          this.errorCodMessage = 'Nominal COD maksimal Rp5.000.000,-'
        } else {
          result = false
          this.errorCodMessage = ''
        }
      }
      return result
    },
    setCustomerPhone(newVal) {
      this.requireCustomerPhone = newVal.requireCustomerPhone
      if (this.customerPhone !== newVal.customerPhone) {
        this.customerPhone = newVal.customerPhone
        if (this.customerPhone.length > 9 && this.profile.partner_is_customer_reputation) {
          this.getCustomerReputation()
        }
      }
    },
    setDataBundling(newVal) {
      const index = this.productSelected.findIndex(item => item.product_id === newVal.product_id && !item.variantSubmit)
      const variant = this.productSelected.findIndex(item => item.product_id === newVal.product_id && item.dataTableShow !== undefined && (item.dataTableShow !== undefined ? item.dataTableShow.variant_complete_name : '') === newVal.dataTableShow.variant_complete_name)
      if (variant > -1) {
        this.productSelected.splice(index, 1)
      } else {
        this.$set(this.productSelected, index, newVal)
      }
      this.addToCart()
    },
    pasteCalculate(event, field) {
      if (field === 'discount') {
        this.discount = (event.clipboardData || window.clipboardData).getData('text')
      } else {
        this.sesuaiNominal = (event.clipboardData || window.clipboardData).getData('text')
      }
      this.loadingCalculate = true
    },
  },
}
